import { Link, useNavigate } from '@remix-run/react';
import { type ReactNode, useState } from 'react';
import { useLocation } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import { type UGCAnalytics } from '../../../analytics/ugc';
import config from '../../../config';
import { apiService } from '../../../services/api-service';
import { AIIcon } from '../../icons/AIIcon';
import { ArrowLeftIcon } from '../../icons/Arrows';
import { useUGCAnalytics } from './utils';

function BetaBadge({
  className,
  // A simple way to hide the beta badge
  disabled = true,
}: {
  className?: string;
  disabled?: boolean;
}) {
  if (disabled) return;
  return (
    <div
      className={`w-12.5 h-3.5 uppercase bg-lp-red-002 text-white text-sms 
      font-bold flex items-center justify-center rounded-sm ${className ?? ''}`}
    >
      beta
    </div>
  );
}

export function CreateCustomGameButton({
  tagId = config.misc.ugcTemplatesTagId,
  width = 'w-53',
  ...props
}: {
  analytics: UGCAnalytics;
  tagId?: number;
  betaLabel?: boolean;
  width?: `w-${number}`;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: tag } = useSWRImmutable(`/tags/${tagId}`, async () => {
    if (!tagId) return;
    const resp = await apiService.tag.getTag(tagId);
    return resp.data.tag;
  });

  function handleClick() {
    if (!tag) return;
    props.analytics.trackCreateCustomGameClicked();
    const params = new URLSearchParams(location.search);
    navigate(`/home/tags/${tag.slug}?${params.toString()}`);
  }

  const disabled = !tag;

  return (
    <div
      className={`bg-gradient-to-bl from-pairing-start to-pairing-end p-px rounded-xl ${
        disabled ? 'opacity-50' : ''
      }`}
    >
      <button
        type='button'
        className={`btn-secondary border-0 ${width} h-10 text-sms font-medium text-white 
      flex items-center justify-center gap-2.5 relative disabled:opacity-100`}
        onClick={handleClick}
        disabled={disabled}
      >
        <div className='hidden'>
          <AIIcon />
        </div>
        <div>Create Custom Game</div>
        {props.betaLabel && (
          <BetaBadge className='absolute top-2 right-0 transform-gpu -translate-y-full' />
        )}
        <div
          className='text-3xs font-bold text-secondary absolute left-1/2 
      transform-gpu -translate-x-1/2 -bottom-1 translate-y-full hidden'
        >
          Powered by AI
        </div>
      </button>
    </div>
  );
}

export function HomeGamePackCustomizationActionGroup() {
  const analytics = useUGCAnalytics();
  return (
    <div className='w-full flex items-center justify-end relative px-4 gap-7.5'>
      <Link
        to='/custom-games'
        className='btn h-10 text-sms font-medium text-white 
        flex items-center justify-center hover:underline'
        onClick={() => analytics.trackMyCustomGamesClicked()}
      >
        My Custom Games
      </Link>
      <CreateCustomGameButton analytics={analytics} betaLabel />
    </div>
  );
}

export function CustomGamePackHeader(props: {
  left?: ReactNode;
  right?: ReactNode;
}) {
  return (
    <header className='w-full h-15 flex items-center justify-between px-10 flex-shrink-0'>
      {props.left}
      {props.right}
    </header>
  );
}

export function CustomGamePackHeaderLeft(props: {
  title: string;
  onClickBack?: () => void;
  onTitleChange?: (title: string) => void;
}) {
  const [editing, setEditing] = useState(false);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onTitleChange?.(e.target.value);
    setEditing(false);
  };

  return (
    <div
      className='text-sms text-icon-gray
      flex items-center justify-center gap-2 relative'
    >
      <button
        type='button'
        className='hover:text-white'
        onClick={props.onClickBack}
      >
        <ArrowLeftIcon className='w-4 h-4 fill-current' />
      </button>
      {editing ? (
        <input
          className='field mb-0 w-50 h-8'
          defaultValue={props.title}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
            }
          }}
          autoFocus
        />
      ) : (
        <button
          type='button'
          className='hover:text-white'
          onClick={() => setEditing(true)}
        >
          {props.title}
        </button>
      )}
      <BetaBadge className='absolute -right-2.5 transform-gpu translate-x-full' />
    </div>
  );
}
